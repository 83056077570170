// extracted by mini-css-extract-plugin
export const contactContainer = "contactSection-module--contactContainer--3yxvd";
export const contactTextContainer = "contactSection-module--contactTextContainer--gL814";
export const quoteHeading = "contactSection-module--quoteHeading--2k7Wm";
export const quoteCopy = "contactSection-module--quoteCopy--1jepk";
export const contactTextGrid = "contactSection-module--contactTextGrid--1SmCU";
export const contactGridTitle = "contactSection-module--contactGridTitle--1mGx1";
export const contactGridAddress = "contactSection-module--contactGridAddress--hSDH8";
export const contactGridSocialLinks = "contactSection-module--contactGridSocialLinks--EVMof";
export const contactIcon = "contactSection-module--contactIcon--1zt09";
export const contactForm = "contactSection-module--contactForm--1fZuY";
export const contactFormColumnSplit = "contactSection-module--contactFormColumnSplit--V-k4B";
export const modalOverlay = "contactSection-module--modalOverlay--352dp";
export const fadeIn = "contactSection-module--fadeIn--3z4x-";
export const modalContainer = "contactSection-module--modalContainer--1FsLg";
export const modalBackground = "contactSection-module--modalBackground--1F9xC";
export const modal = "contactSection-module--modal--2euEm";
export const modalMenuExitBtn = "contactSection-module--modalMenuExitBtn--10w4Q";
export const rotate = "contactSection-module--rotate--1fda4";
export const modalLogo = "contactSection-module--modalLogo--2wUPT";
export const modalIconContainer = "contactSection-module--modalIconContainer--2pU45";
export const modalMenu = "contactSection-module--modalMenu--25wNg";
export const map = "contactSection-module--map--262T3";
export const placeBackground = "contactSection-module--placeBackground--2XcEq";
export const placeCardBackground = "contactSection-module--placeCardBackground--SiDwc";
export const placecard = "contactSection-module--placecard--3gRia";
export const placedesclarge = "contactSection-module--placedesclarge--BVZem";
export const placename = "contactSection-module--placename--wKKLE";
export const placeaddress = "contactSection-module--placeaddress--1mDOq";
export const navigate = "contactSection-module--navigate--ODRAC";
export const navigatelink = "contactSection-module--navigatelink--30To2";
export const navigateicon = "contactSection-module--navigateicon--2uSkq";
export const navigatetext = "contactSection-module--navigatetext--y7fP8";
export const reviewbox = "contactSection-module--reviewbox--U8MYO";
export const reviewnumber = "contactSection-module--reviewnumber--1S0hl";
export const reviewstar = "contactSection-module--reviewstar--uYJAP";
export const reviewboxlink = "contactSection-module--reviewboxlink--2msmI";
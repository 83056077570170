import React, { useState, Fragment } from "react"
import Media from "react-media"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Modal from "react-modal"

import { Icon } from "@iconify/react"
import messengerIcon from "@iconify/icons-cib/messenger"
import whatsappIcon from "@iconify/icons-cib/whatsapp"
import facebookF from "@iconify/icons-cib/facebook-f"
import instagramIcon from "@iconify/icons-cib/instagram"
import youtubeIcon from "@iconify/icons-cib/youtube"

import * as contactSectionStyles from "./contactSection.module.css"
import * as headerStyles from "./header.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default function ContactSection(props) {
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  Modal.setAppElement("body")

  return (
    <div className={contactSectionStyles.contactContainer}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={headerStyles.modalContainer}
        overlayClassName={headerStyles.modalOverlay}
        contentLabel="Modal Menu"
        closeTimeoutMS={300}
      >
        <ModalMenu closeModal={closeModal} />
      </Modal>
      <ScrollAnimation
        animateIn="animate__fadeIn"
        animateOnce={true}
        duration={0.5}
        initiallyVisible={true}
      >
        {props.contactPage ? <h1>GET IN TOUCH</h1> : <h2>GET IN TOUCH</h2>}
      </ScrollAnimation>
      <StaticMap />
      <ScrollAnimation
        animateIn="animate__fadeIn"
        animateOnce={true}
        duration={0.5}
      >
        <div className={contactSectionStyles.contactTextContainer}>
          <span className={contactSectionStyles.quoteHeading}>
            Need a quote?
          </span>
          <p id={contactSectionStyles.quoteCopy}>
            Send us a message, give us a call or pop in for a free consultation!
          </p>
          <ContactInfo />
          <button
            className="buttonStyle1"
            style={{ justifySelf: "center" }}
            onClick={openModal}
          >
            SEND US A MESSAGE
          </button>
        </div>
      </ScrollAnimation>
    </div>
  )
}

class ContactInfo extends React.Component {
  render() {
    return (
      <div className={contactSectionStyles.contactTextGrid}>
        <div id={contactSectionStyles.contactGridAddress}>
          <span className={contactSectionStyles.contactGridTitle}>ADDRESS</span>
          <p>
            <span>12 Fairways Business Centre,</span>
            <br />
            <span>Airport Service Road,</span>
            <br />
            <span>Portsmouth, Hampshire,</span>
            <br />
            <span>PO3 5NU</span>
            <br />
            <span>United Kingdom</span>
          </p>
        </div>
        <div id={contactSectionStyles.contactGridEmail}>
          <span className={contactSectionStyles.contactGridTitle}>EMAIL</span>
          <br />
          <a href="mailto:info@tintex.co.uk">
            <p>info@tintex.co.uk</p>
          </a>
        </div>
        <div id={contactSectionStyles.contactGridPhone}>
          <span className={contactSectionStyles.contactGridTitle}>PHONE</span>
          <br />
          <a href="tel:+447479555325">
            <p>07479 555 325</p>
          </a>
        </div>
        <div id={contactSectionStyles.contactGridOpeningTimes}>
          <span className={contactSectionStyles.contactGridTitle}>
            OPENING TIMES
          </span>
          <p>Mon - Sun 10:00 - 19:00</p>
        </div>
        <div id={contactSectionStyles.contactGridSocialLinks}>
          <a
            target="_blank"
            href="http://m.me/tintexportsmouth"
            rel="noopener noreferrer"
          >
            <Icon
              title="FB Messenger"
              icon={messengerIcon}
              className={contactSectionStyles.contactIcon}
            />
          </a>
          <a
            target="_blank"
            href="https://wa.me/447479555325"
            rel="noopener noreferrer"
          >
            <Icon
              title="WhatsApp"
              icon={whatsappIcon}
              className={contactSectionStyles.contactIcon}
            />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/tintexportsmouth/"
            rel="noopener noreferrer"
          >
            <Icon
              title="Facebook"
              icon={facebookF}
              className={contactSectionStyles.contactIcon}
            />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/tintex_portsmouth"
            rel="noopener noreferrer"
          >
            <Icon
              title="Instagram"
              icon={instagramIcon}
              className={contactSectionStyles.contactIcon}
            />
          </a>
          <a href="/" style={{ display: "none" }}>
            <Icon
              title="Youtube"
              icon={youtubeIcon}
              className={contactSectionStyles.contactIcon}
            />
          </a>
        </div>
      </div>
    )
  }
}

function Message(props) {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    tel: "",
    subject: "",
    message: "",
  })

  const [formStatus, setFormStatus] = useState(false)

  const onChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const submitForm = async e => {
    e.preventDefault()

    try {
      const response = await fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify(formState),
      })

      if (!response.ok) {
        setFormStatus("Error")
        return
      }

      setFormStatus("Success")
    } catch (e) {
      //error
      setFormStatus("Error")
    }
  }

  return (
    <div id="contactForm">
      <h2>SEND US A MESSAGE</h2>
      {formStatus === "Success" ? (
        <div>
          Thanks! Your message has been sent. We will get back to you within the
          next 24-48 hours.
        </div>
      ) : formStatus === "Error" ? (
        <div>
          <p>
            Error! The contact form has failed to send. Please try again or
            contact us using another way.
          </p>
          <br />
          <div id={contactSectionStyles.contactGridEmail}>
            <span className={contactSectionStyles.contactGridTitle}>EMAIL</span>
            <br />
            <a href="mailto:info@tintex.co.uk">
              <p>info@tintex.co.uk</p>
            </a>
          </div>
          <br />
          <div id={contactSectionStyles.contactGridPhone}>
            <span className={contactSectionStyles.contactGridTitle}>PHONE</span>
            <br />
            <a href="tel:+447479555325">
              <p>07479 555 325</p>
            </a>
          </div>
          <br />
          <button className="buttonStyle1" onClick={() => setFormStatus("")}>
            TRY AGAIN
          </button>
        </div>
      ) : (
        <div>
          <form
            method="POST"
            name="contact"
            id={contactSectionStyles.contactForm}
            onSubmit={submitForm}
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <label>
              Name
              <input
                type="text"
                name="name"
                id="name"
                onChange={onChange}
                maxlength="50"
                required
              />
            </label>
            <div id={contactSectionStyles.contactFormColumnSplit}>
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={onChange}
                  maxlength="50"
                  required
                />
              </label>
              <label>
                Phone
                <input
                  type="tel"
                  name="tel"
                  id="tel"
                  onChange={onChange}
                  maxlength="20"
                />
              </label>
            </div>
            <label>
              Subject
              <input
                type="text"
                name="subject"
                id="subject"
                onChange={onChange}
                maxlength="50"
                required
              />
            </label>
            <label>
              Message
              <textarea
                name="message"
                id="message"
                rows="5"
                onChange={onChange}
                maxlength="500"
                required
              />
            </label>
            <div>
              <button type="submit" className="buttonStyle1">
                SEND MESSAGE
              </button>
              <input type="reset" value="CLEAR" className="buttonStyle3" />
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

class ModalMenu extends React.Component {
  render() {
    return (
      <div id={contactSectionStyles.modalBackground}>
        <div id={contactSectionStyles.modal}>
          <div className={contactSectionStyles.modalIconContainer}>
            <svg
              id={contactSectionStyles.modalMenuExitBtn}
              title="Close"
              width="23"
              height="23"
              viewBox="0 0 23 23"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                this.props.closeModal()
              }}
            >
              <rect
                width="28.3514"
                height="2.53064"
                transform="matrix(0.702868 0.71132 -0.72002 0.693953 1.85437 0.212311)"
              />
              <rect
                width="28.3514"
                height="2.53064"
                transform="matrix(-0.702868 0.71132 -0.72002 -0.693953 22.0322 1.89151)"
              />
            </svg>
          </div>
          <div id={contactSectionStyles.modalMenu}>
            <Message />
          </div>
        </div>
      </div>
    )
  }
}

class StaticMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = { rating: "5.0", user_ratings_total: "550+" }
  }

  /** componentDidMount() {
    var axios = require("axios")

    var config = {
      method: "get",
      url:
        "https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJ4bEM8qtddEgRA5In1s5D0Xk&fields=rating,user_ratings_total&key=AIzaSyCkchWxySZyEhsG74NUVDDnxbztIPaQDMk",
      headers: {},
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
      })
      .catch(function (error) {
        console.log(error)
      })
  }  */

  render() {
    return (
      <div>
        <div className={contactSectionStyles.placeBackground}>
          <div className={contactSectionStyles.placeCardBackground}>
            <div>
              <div className={contactSectionStyles.placecard}>
                <div className={contactSectionStyles.placedesclarge}>
                  <div className={contactSectionStyles.placename}>Tintex</div>
                  <div className={contactSectionStyles.placeaddress}>
                    12 Fairways Business Centre, Airport Service Rd, Portsmouth
                    PO3 5NU, United Kingdom
                  </div>
                </div>
                <div className={contactSectionStyles.navigate}>
                  <div className={contactSectionStyles.navigate}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://maps.google.com/maps?ll=50.822208,-1.050345&amp;z=16&amp;t=m&amp;hl=en-GB&amp;gl=GB&amp;mapclient=embed&amp;daddr=Tintex%2012%20Airport%20Service%20Rd%20Portsmouth%20PO3%205NU@50.8222077,-1.0503453"
                      className={contactSectionStyles.navigatelink}
                    >
                      <div className={contactSectionStyles.navigateicon}></div>
                      <div className={contactSectionStyles.navigatetext}>
                        Directions
                      </div>
                    </a>
                  </div>
                </div>
                <div className={contactSectionStyles.reviewbox}>
                  <div className={contactSectionStyles.reviewnumber}>
                    {this.state.rating}
                  </div>
                  <div className={contactSectionStyles.reviewstar}></div>
                  <div className={contactSectionStyles.reviewstar}></div>
                  <div className={contactSectionStyles.reviewstar}></div>
                  <div className={contactSectionStyles.reviewstar}></div>
                  <div className={contactSectionStyles.reviewstar}></div>
                  <a
                    href="http://www.google.co.uk/search?q=Tintex,+12+Airport+Service+Rd,+Portsmouth+PO3+5NU&amp;ludocid=8777871704357048835#lrd=0x48745dabf20cb1e1:0x79d143ced6279203,1"
                    rel="noreferrer"
                    className={contactSectionStyles.reviewboxlink}
                  >
                    {this.state.user_ratings_total} reviews
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="https://g.page/tintexportsmouth?share" rel="noreferrer">
          <Map />
          <div id="map" />
        </a>
      </div>
    )
  }
}

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, displayLargeMap: false }
  }

  componentDidMount() {
    let width = window.innerWidth
    this.setState({ windowWidth: width, displayLargeMap: true })
  }

  render() {
    const { allImageSharp } = useStaticQuery(graphql`
      query {
        allImageSharp {
          nodes {
            fluid(maxWidth: 1440, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

    const smallMap = allImageSharp.nodes.find(
      n => n.fluid.originalName === "tintex-map-small.png"
    ).fluid

    const largeMap = allImageSharp.nodes.find(
      n => n.fluid.originalName === "tintex-map.png"
    ).fluid

    return (
      <div>
        {this.state.displayLargeMap && (
          <Media
            queries={{
              small: "(max-width: 599px)",
              medium: "(min-width: 600px)",
            }}
          >
            {matches => (
              <Fragment>
                {matches.small && (
                  <Img
                    fluid={smallMap}
                    alt="Google Map of Tintex Portsmouth"
                    className={contactSectionStyles.map}
                    fadeIn={false}
                    loading="lazy"
                    draggable={false}
                  />
                )}
                {matches.medium && (
                  <Img
                    fluid={largeMap}
                    alt="Google Map of Tintex Portsmouth"
                    className={contactSectionStyles.map}
                    fadeIn={false}
                    loading="lazy"
                    draggable={false}
                  />
                )}
              </Fragment>
            )}
          </Media>
        )}
      </div>
    )
  }
}

// extracted by mini-css-extract-plugin
export const container = "footer-module--container--HXu2X";
export const colContainer = "footer-module--colContainer--3-uZf";
export const col1 = "footer-module--col1--AT64d";
export const col2 = "footer-module--col2--2N1LQ";
export const col3 = "footer-module--col3--7FWpw";
export const col4 = "footer-module--col4--3P8ic";
export const h5 = "footer-module--h5--3sQYm";
export const colTitle = "footer-module--colTitle--1tzNb";
export const footerContact = "footer-module--footerContact--iutam";
export const logo = "footer-module--logo--3sxBO";
export const logoImg = "footer-module--logoImg--6h01r";
export const copyright = "footer-module--copyright--3yIxz";
export const companyNo = "footer-module--companyNo--1E5lA";
export const activeLink = "footer-module--activeLink--3tRUv";